import { Link } from "gatsby"
import If from "components/If"
import Card from "components/Card"
import Hint from "components/Hint"
import EmptyState from "components/EmptyState"
import Icon from "components/Icon"
import { Table, TableList, TableItem } from "components/Table"

import { HEADERS } from "./constants"
import "./CompaniesTable.styl"

const CompaniesTable = ({ companies, currentPage }) => {
  return (
    <div className="companies-table">
      <If
        condition={companies.length}
        renderIf={
          <Table headers={HEADERS}>
            {companies?.map((company) => (
              <TableList key={company.id}>
                <TableItem>{company.name}</TableItem>
                <TableItem>{company.qt_masters}</TableItem>
                <TableItem>{company.qt_admins}</TableItem>
                <TableItem>{company.qt_salesman}</TableItem>
                <TableItem className="companies-table__actions">
                  <Link
                    data-test="see-team"
                    className="companies-table__actions--link"
                    to={`${ROUTES.users.path}?group_id=${company.id}`}
                  >
                    <Icon name="user" className="companies-table__icon" />
                    <Hint
                      isSideways={true}
                      hasAutoWidth
                      balloon="right-side"
                      className="companies-table__hint"
                    >
                      Ver time
                    </Hint>
                  </Link>
                  <Link
                    data-test="edit-company"
                    className="companies-table__actions--link"
                    to={`${ROUTES.editCompany.path}?group_id=${company.id}`}
                  >
                    <Icon name="pencil" className="companies-table__icon" />
                    <Hint
                      isSideways={true}
                      hasAutoWidth
                      balloon="right-side"
                      className="companies-table__hint"
                    >
                      Editar
                    </Hint>
                  </Link>
                </TableItem>
              </TableList>
            ))}
          </Table>
        }
        renderElse={
          <Card className="companies-table__card">
            <EmptyState
              className="companies-table__empty-state"
              text="Nenhuma empresa encontrada"
            />
          </Card>
        }
      />
    </div>
  )
}

export default CompaniesTable
