import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"
import { TabsNavigation } from "components/Tabs"
import TableHeader from "./components/TableHeader"
import CompaniesTable from "./components/CompaniesTable"

import { TABS } from "../constants"

import useCompanies from "./hooks"

const Companies = () => {
  const { companies, pagination, refresh } = useCompanies()

  return (
    <Can I="management" the="companies">
      <PageHeader title="Empresas" />
      <section className="companies">
        <TabsNavigation tabs={TABS} />
        <TableHeader onSearch={refresh} />
        <CompaniesTable
          companies={companies}
          currentPage={pagination.current_page}
        />
        <Pagination
          currentPage={pagination.current_page}
          pages={pagination.total_pages}
          onChange={refresh}
        />
      </section>
    </Can>
  )
}

export default Companies
