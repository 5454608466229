import { useCallback, useEffect, useState, useRef } from "react"
import services from "services"
import auth from "utils/auth"
import { useParameters } from "hooks/useParameters"
import _pagination from "utils/pagination"

const useCompanies = () => {
  const {
    current_page: [currentPage],
  } = useParameters("current_page")
  const [companies, setCompanies] = useState([])
  const [pagination, setPagination] = useState({})
  const queries = useRef({})
  const user = auth.getUser()

  const setQueries = (values) => {
    queries.current = { ...queries.current, ...values }
  }

  const handleFetch = useCallback(({ data, pagination }) => {
    setCompanies(data)
    setPagination({
      ...pagination,
      total_pages: _pagination.getTotalPages(pagination),
    })
  }, [])

  const fetchCompanies = useCallback(
    (props) => {
      setQueries(props)

      services.user
        .companies(
          {
            user_id: user?.user_id,
            page: currentPage,
            ...queries.current,
            ...props,
          },
          { hideLoader: true }
        )
        .then(handleFetch)
    },
    [user.user_id, currentPage, handleFetch]
  )

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  return {
    companies,
    pagination,
    refresh: fetchCompanies,
  }
}

export default useCompanies
